<template>
  <section class="chat-box">
    <div class="chat-box-list-container" ref="chatbox">
      <ul class="chat-box-list">
        <li
          class="message"
          v-for="(message, idx) in messages"
          :key="idx"
          :class="message.author"
        >
          <p>
            <span>{{ message.text }}</span>
          </p>
        </li>
      </ul>
    </div>
    <div class="chat-inputs">
      <input
        type="text"
        v-model="message"
        @keyup.enter="sendMessage"
        placeholder="ask someteting..."
      />
      <button class="btn btn-primary" @click="sendMessage">Ask</button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ChatBox',
  data: () => ({
    message: '',
    messages: []
  }),
  methods: {
    sendMessage() {
      const message = this.message
      var reply = ''
      this.messages.push({
        text: message,
        author: 'client'
      })

      this.message = ''
      this.$axios.post('https://miranda.hillsweb.com/api/',
          {question: message}
      )
      .then(res => {
        console.log(res.data)
        reply = "I seem to have some connectivity problem, sorry"
        if(res.data.reply) {
          reply = res.data.reply
        }
        this.messages.push({
          text: reply,
          author: 'miranda'
        })

        this.$nextTick(() => {
          this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight + 50
        })
      })
    }
  }
}
</script>

